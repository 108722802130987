import React from 'react'
import styles from './leadership-team.module.scss'

const LeadershipTeam = () => {
    return (
        <section>
            <div className="container">
                <h2>Leadership Team</h2>
                <div className={styles.bioWrapper}>
                    <div className={`md:flex ${styles.bio}`}>
                        <div className={styles.bioPicture}>
                            <img src="/rshalwitz@2x.png" alt="Robert Shalwitz"/>
                            <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/robert-shalwitz-7b841346/" className={styles.bioLinkedin}>
                                <img src="/linkedin-icon.svg" alt="LinkedIn"/>
                            </a>
                        </div>
                        <div className={styles.bioContent}>
                            <h3><strong>Robert Shalwitz,</strong> M.D.</h3>
                            <p>Dr. Shalwitz is a pediatric endocrinologist with extensive background in clinical research and experience with the FDA. He has led teams to multiple successful new and supplemental drug approvals (NDAs and sNDAs) while at Abbott Laboratories and Reliant Pharmaceuticals. As one of the founders of both Akebia Therapeutics and Aerpio Therapeutics, Dr. Shalwitz participated in raising over $200 Million, including a successful public offering of Akebia.</p>
                        </div>
                    </div>

                    <div className={`md:flex ${styles.bio} ${styles.bioReverse}` }>
                        <div className={styles.bioPicture}>
                            <img src="/rwingo@2x.png" alt="Rachael Wingo"/>
                            <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/rachaelwingo/" className={styles.bioLinkedin}>
                                <img src="/linkedin-icon.svg" alt="LinkedIn"/>
                            </a>
                        </div>
                        <div className={styles.bioContent}>
                            <h3><strong>Rachael Wingo,</strong> MBA</h3>
                            <p>Rachael Wingo is an accomplished financial leader with success in the private equity and venture capital spaces. Rachael’s leadership experience spans business development, financial strategy and executive leadership at companies including Cardinal Health, Astute Solutions and Velosio. Rachael received her bachelor's degree and MBA from the University of Alabama.</p>
                        </div>
                    </div>

                    <div className={`md:flex ${styles.bio}` }>
                        <div className={styles.bioPicture}>
                            <img src="/ishalwitz@2x.png" alt="Isaiah Shalwitz"/>
                            <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/isaiah-shalwitz-11aa4b3/" className={styles.bioLinkedin}>
                                <img src="/linkedin-icon.svg" alt="LinkedIn"/>
                            </a>
                        </div>
                        <div className={styles.bioContent}>
                            <h3><strong>Isaiah Shalwitz</strong></h3>
                            <p>Isaiah Shalwitz is a biostatistician and experienced program manager in pharmaceutical development. Isaiah worked in multiple aspects of pharmaceutical development during his eight-year tenure with Akebia Therapeutics. Most recently, Isaiah was the program manager for the team during non-clinical development, including IND enabling studies, of AKB-6899.</p>
                        </div>
                    </div>

                    <div className={`md:flex ${styles.bio} ${styles.bioReverse}` }>
                        <div className={styles.bioPicture}>
                            <img src="/cflinn@2x.png" alt="Cindy Flinn"/>
                            <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/cindy-flinn/" className={styles.bioLinkedin}>
                                <img src="/linkedin-icon.svg" alt="LinkedIn"/>
                            </a>
                        </div>
                        <div className={styles.bioContent}>
                            <h3><strong>Cindy Flinn,</strong> M.S.</h3>
                            <p>Cindy Flinn is an experienced Program Leader, having managed numerous Drug Development programs from pre-clinical to late stage to include orphan drug development.  She has worked in multiple therapeutic areas including infectious disease, Cardiovascular and Metabolic diseases at both startup and large established pharma companies. She was a successful project analyst and planner at Pfizer within the Research and Development Program.</p>
                        </div>
                    </div>
                    
                </div>
            </div>

            <div className="container">
                <h3 className={styles.h3}>Scientific Advisory Board</h3>
                <div className={styles.board}>
                    <div className={styles.member}>
                        <img src="/kbeckman.jpg" alt="Kenneth Beckman"/>
                        <h4><strong>Kenneth Beckman,</strong> M.D.</h4>
                    </div>
                    <div className={styles.member}>
                        <img src="/edonnenfeld.jpg" alt="Eric Donnenfeld"/>
                        <h4><strong>Eric Donnenfeld,</strong> M.D.</h4>
                    </div>
                    <div className={styles.member}>
                        <img src="/bfriedland.jpg" alt="Beth Friedland"/>
                        <h4><strong>Beth Friedland,</strong> M.D.</h4>
                    </div>
                    <div className={styles.member}>
                        <img src="/eholland.jpg" alt="Edward J. Holland"/>
                        <h4><strong>Edward J. Holland,</strong> M.D.</h4>
                    </div>
                    <div className={styles.member}>
                        <img src="/mmacsai.jpg" alt="Marian Macsai"/>
                        <h4><strong>Marian Macsai,</strong> M.D.</h4>
                    </div>
                    <div className={styles.member}>
                        <img src="/twillis.jpg" alt="Tim Willis"/>
                        <h4><strong>Tim Willis</strong></h4>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default LeadershipTeam